import {
  Availability,
  ImportDetails,
  PrimeBadgeData,
  ReturnsPolicy,
} from '@amzn/mulberry-external-typescript-client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';
import ProductDetailBox, {
  ProductDetailBoxProps,
} from 'src/components/ProductDetailBox/ProductDetailBox';
import {
  PREORDER_PRICE_GUARANTEE_TEXT,
  SHIPPED_BY_AMAZON_TEXT,
  TERMS_TEXT,
} from 'src/constants/strings';
import {
  buildCombinedShippingAndImportCostString,
  formatAvailabilityString,
} from 'src/utils/utils';

export interface PrimaryInfoBoxStyles {
  primaryInfoBoxContainer?: React.CSSProperties;
  primaryInfoBoxHeader?: React.CSSProperties;
  primaryInfoBoxText?: React.CSSProperties;
  primaryInfoBoxPrice?: React.CSSProperties;
  primaryInfoBoxAvailability?: React.CSSProperties;
  primaryInfoBoxReturn?: React.CSSProperties;
  primaryInfoBoxAmazonInfo?: React.CSSProperties;
  primaryInfoBoxPreOrderPriceGuarantee?: React.CSSProperties;
  primaryInfoBoxPreOrderPriceGuaranteeTermsLink?: React.CSSProperties;
  primaryInfoBoxFeesMessage?: React.CSSProperties;
}

type PrimaryInfoBoxProps = {
  title: string | undefined;
  price: string | undefined;
  availability: Availability | undefined;
  returns: ReturnsPolicy | undefined;
  primeBadgeData?: PrimeBadgeData | undefined;
  styles?: PrimaryInfoBoxStyles;
  details?: ProductDetailBoxProps;
  deliveryMessage?: string | null;
  importDetails?: ImportDetails;
};

const PrimaryInfoBox = (props: PrimaryInfoBoxProps) => {
  const importDetailsFeeNumber = Number(props?.importDetails?.fee?.replace('$', '')) ?? 0;
  const hasShippingAndImportCosts: boolean =
    !!props.importDetails?.fee &&
    importDetailsFeeNumber !== 0 &&
    !!props.importDetails?.message &&
    !!props.deliveryMessage;

  if (!props.title && !props.price && !props.availability && !props.returns) {
    return null;
  }
  // format the strings as "str1, str2" if both exist, otherwise show one if one exists, none if neither exist
  const availabilityText = formatAvailabilityString(
    props.availability?.primaryMessage,
    props.availability?.secondaryMessage,
  );
  const returnsText = props.returns?.policyMessage ?? '';
  const isPreOrder = props.availability?.type === 'PREORDER';

  return (
    <div
      className="primaryInfoBoxContainer"
      data-testid="primaryInfoBoxContainer"
      style={props.styles?.primaryInfoBoxContainer}
    >
      {props.title && (
        <h2
          className="primaryInfoBoxHeader"
          data-testid="primaryInfoBoxHeader"
          style={props.styles?.primaryInfoBoxHeader}
        >
          {props.title}
        </h2>
      )}
      {props.price && (
        <div
          className="primaryInfoBoxText"
          id="primaryInfoBoxPrice"
          data-testid="primaryInfoBoxPrice"
          style={{
            ...props.styles?.primaryInfoBoxText,
            ...props.styles?.primaryInfoBoxPrice,
          }}
        >
          {props.price}
        </div>
      )}
      {props.primeBadgeData?.isPrime && props.primeBadgeData?.miniBadgeImageUrl && (
        <img
          data-testid={'primeBadge'}
          src={props.primeBadgeData?.miniBadgeImageUrl}
          alt={props.primeBadgeData.imageAltText}
        />
      )}
      <div
        className="primaryInfoBoxText"
        id="primaryInfoBoxAmazonInfo"
        data-testid="primaryInfoBoxAmazonInfo"
        style={{
          ...props.styles?.primaryInfoBoxText,
          ...props.styles?.primaryInfoBoxAmazonInfo,
        }}
      >
        {SHIPPED_BY_AMAZON_TEXT}
      </div>
      {hasShippingAndImportCosts && (
        <div
          className="primaryInfoBoxCombinedFeeMessage"
          id="primaryInfoBoxCombinedFeeMessage"
          data-testid="primaryInfoBoxCombinedFeeMessage"
          style={props.styles?.primaryInfoBoxFeesMessage}
        >
          {buildCombinedShippingAndImportCostString(
            props.importDetails?.fee ?? '',
            props.deliveryMessage ?? '',
            props.importDetails?.message ?? '',
          )}
        </div>
      )}
      {props.deliveryMessage && !hasShippingAndImportCosts && (
        <div
          className="primaryInfoBoxDeliveryMessage"
          id="primaryInfoBoxDeliveryMessage"
          data-testid="primaryInfoBoxDeliveryMessage"
          style={props.styles?.primaryInfoBoxFeesMessage}
        >
          {props.deliveryMessage}
        </div>
      )}
      {props.importDetails && importDetailsFeeNumber !== 0 && !hasShippingAndImportCosts && (
        <div
          className="primaryInfoBoxImportDetails"
          id="primaryInfoBoxImportDetails"
          data-testid="primaryInfoBoxImportDetails"
          style={props.styles?.primaryInfoBoxFeesMessage}
        >
          {props.importDetails.message}
        </div>
      )}
      {props.details && <ProductDetailBox testId="productDetailBox" {...props.details} />}
      {isPreOrder && (
        <div
          className="primaryInfoBoxText"
          id="primaryInfoBoxPreOrderPriceGuarantee"
          data-testid="primaryInfoBoxPreOrderPriceGuarantee"
          style={{
            ...props.styles?.primaryInfoBoxText,
            ...props.styles?.primaryInfoBoxPreOrderPriceGuarantee,
          }}
        >
          <CheckCircleIcon />
          {PREORDER_PRICE_GUARANTEE_TEXT}
          <a
            href={'https://www.amazon.com/gp/help/customer/display.html?nodeId=G4BRP7LCB9DSDNCR'}
            rel="noreferrer"
            target="_blank"
            style={props.styles?.primaryInfoBoxPreOrderPriceGuaranteeTermsLink}
          >
            {TERMS_TEXT}
          </a>
        </div>
      )}
      {availabilityText && (
        <div
          className="primaryInfoBoxText"
          id="primaryInfoBoxAvailability"
          data-testid="primaryInfoBoxAvailability"
          style={{
            ...props.styles?.primaryInfoBoxText,
            ...props.styles?.primaryInfoBoxAvailability,
          }}
        >
          {availabilityText}
        </div>
      )}
      {returnsText && (
        <div
          className="primaryInfoBoxText"
          id="primaryInfoBoxReturn"
          data-testid="primaryInfoBoxReturn"
          style={{
            ...props.styles?.primaryInfoBoxText,
            ...props.styles?.primaryInfoBoxReturn,
          }}
        >
          {returnsText}
        </div>
      )}
    </div>
  );
};

export default PrimaryInfoBox;
